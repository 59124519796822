import { createApp } from 'vue'
import App from './App.vue'
import i18n from './lang/index';

// 此段代码写入main.js中
import axios from './utils/request.js'
import ElementPlus from 'element-plus'
import VueHighlightJS from 'vue-highlightjs'

import 'highlight.js/styles/atom-one-dark.css'
import './sass-style/common.scss';
import './sass-style/theme.scss';
import 'element-plus/dist/index.css'


import router from './router/index'
import store from './store';

const app = createApp(App)
    .use(i18n)
    .use(VueHighlightJS)
    .use(ElementPlus)
    .use(axios)
    .use(router)
    .use(store);

app.config.errorHandler = function (err, vm, info) {
    // `err`是错误对象
    console.error('捕获到错误:', err);
    // `vm`是发生错误的Vue实例
    console.log('发生错误的组件实例:', vm);
    // `info`是一个包含错误来源信息的字符串
    console.log('错误来源:', info);
};
app.mount('#app');

const productType = PRODUCT_TYPE;

const productInfo = productType === 'adult' ? {
    platform: 'pc',
    theme: 'dark'
} : {
    platform: 'mobile',
    theme: 'light'
}

window.productType = productType
document.body.setAttribute('data-theme', productInfo.theme)
document.body.setAttribute('data-platform', productInfo.platform)