/**
 *  1.axios配置  2.请求拦截器 3.接口报错统一处理
 */

// 引入模块
// import store from '@/store/index' //登录后将token存储在store 中因此需要引入
import qs from 'qs' // 使用qs 将数据格式转为表单提交，看后台需要什么类型的传参，非必须
import axios from 'axios'
import emitter from "./eventBus";

function getQueryParams(str = '') {
  const arr = str.split('&');
  return arr.reduce((total, cur) => {
    let [key, value] = cur.split('=');
    total[key] = value;
    return total
  }, {})
}
export function getHashQuery() {
  const hash = decodeURI(window.location.hash);
  if (hash.startsWith('#')) {
    const queryString = hash.split('?')[1]; // 获取query
    const queryParams = getQueryParams(queryString);
    return queryParams
  }
  return {};
}

// axios初始化：延迟时间，主路由地址,是否允许跨域
const isAdult = PRODUCT_TYPE === 'adult';
const getBaseUrl = () => {
  /**
   * 区分环境与项目
   * 该项目同时适用于大人英语与儿童英语
   * 入口处打包命令参数不同，接口也区分于两套
   */
  if (process.env.NODE_ENV === 'development') {
    return isAdult ? '/api' : '/mobile-api'
  } else {
    return isAdult ? '//chat.onechatai.cn' : '//dev-api.cckidabc.com/';
  }
}
let instance = axios.create({
  baseURL: getBaseUrl(),
  timeout: 10000,
});

// 设置拦截器
instance.interceptors.request.use(function (config) {
  let token = localStorage.getItem('vb-token')
  let aiVideo = config.url.indexOf("aivideo") > -1;
  const hashQuery = getHashQuery();
  if (aiVideo) {
    config.headers["Authorize"] = localStorage.getItem('vb-token')
    return config;
  }
  if (!isAdult) {
    console.log('hashQuery', hashQuery)
    config.headers["Cetoken"] = hashQuery.token
    return config;
  }
  if (config.url.indexOf("voiceGetUrl") !== -1) {
    if (token && token != null && token != '') {
      config.headers = {
        //添加token，unit，busType，processId
        'Content-Type': 'application/x-www-form-urlencoded', //配置请求头
        "Authorize": token,
        token: 1,
        unit: 1,
        busType: 1,
        processId: 1,
      }
    } else {
      config.headers = {
        //添加token，unit，busType，processId
        'Content-Type': 'application/x-www-form-urlencoded', //配置请求头
        token: 1,
        unit: 1,
        busType: 1,
        processId: 1,
      }
    }

  } else {
    if (token && token != null && token != '') {
      config.headers = {
        //添加token，unit，busType，processId
        'Content-Type': 'application/json', //配置请求头
        "Authorize": token,
        token: 1,
        unit: 1,
        busType: 1,
        processId: 1,
      }
    } else {
      config.headers = {
        //添加token，unit，busType，processId
        'Content-Type': 'application/json', //配置请求头
        token: 1,
        unit: 1,
        busType: 1,
        processId: 1,
      }
    }
  }
  return config;
}, function (error) {
  console.log("拦截器---err")
  //请求错误时做些事
  return Promise.reject(error);
});
//响应拦截器
instance.interceptors.response.use(function (response) {
  if (+response.data.code === 401 && !location.hash.startsWith('#/login')) {
    location.replace('#/login?redirect=' + location.hash.split('#')[1])
  }
  return response;
}, function (error) {
  //请求错误时做些事
  return Promise.reject(error);
});

// 是否销毁拦截器
// 1.给拦截器起个名称 var myInterceptors = instance.interceptors.requesst.use();
// 2.instance.interceptors.request.eject(myInterceptor);

// 请求成功的回调
function checkStatus(res) {
  //请求结束成功
  if (res.status === 200 || res.status === 304) {
    return res.data
  }
  return {
    code: 0,
    msg: res.data.msg || res.statusText,
    data: res.statusText
  }
}
// 请求失败的回调
function checkCode(res) {
  //   if (res.code === 0) {
  //     throw new Error(res.msg)
  //   }
  return res
}
function handleNoFound(url, params, promiseInstance) {
  return new Promise(resolve => promiseInstance(url, params).then(res => resolve(res)).catch((res) => { resolve(res) }))
}

// 处理404请求找不到的情况(兼容成人与儿童)
//模块化导出
export default {
  get: (url, params) => handleNoFound(url, params, (url, params) => {
    if (!url) return;
    return instance({
      method: 'get',
      url: url,
      params,
      timeout: 30000
    }).then(checkStatus).then(checkCode)
  }),
  post: (url, params) => handleNoFound(url, params, (url, data) => {
    if (!url) return;
    return instance({
      method: 'post',
      url: url,
      data: qs.stringify(data),
      timeout: 30000
    }).then(checkStatus).then(checkCode).catch(handleNoFound)
  }),
  postform: (url, params) => handleNoFound(url, params, (url, data) => {
    if (!url) return;
    return instance({
      method: 'post',
      url: url,
      data: data,
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      timeout: 30000
    }).then(checkStatus).then(checkCode)
  }),
  postjson: (url, params) => handleNoFound(url, params, (url, data) => {
    if (!url) return;
    return instance({
      method: 'post',
      url: url,
      data: data,
      timeout: 30000
    }).then(checkStatus).then(checkCode)
  }),
  postFile: (url, params) => handleNoFound(url, params, (url, data) => {
    if (!url) return;
    return instance({
      method: 'post',
      url: url,
      data
    }).then(checkStatus).then(checkCode)
  })
}