import { createRouter, createWebHashHistory } from 'vue-router'

const routerHistory = createWebHashHistory()

import { defineAsyncComponent } from 'vue'

const AsyncComp = (url) => defineAsyncComponent(() => import('../view/video-list.vue'))

// import Vpage from '../view/page.vue'
// import Vlogin from '../view/login.vue'
// import VVideo from '../view/video-theater.vue'
// import VideoList from '../view/video-list.vue';
// import tokenLogin from "../view/tokenLogin.vue";
// import SentenceSearch from "../view/sentencesearch.vue";
// import LastRecord from "../view/lastrecord.vue";
// import VMind from '../view/mind.vue'

import "../assets/font/iconfont.css"

// 定义路由
const routes = [
    {
        path: '/',
        redirect: { name: 'ai-video' }
    },
    {
        path: '/page',
        name: 'page',
        component: defineAsyncComponent(() => import('../view/page.vue')),
        meta: { title: 'WordMap.net' }
    },
    {
        path: '/mind/:word',
        name: 'mind',
        component: defineAsyncComponent(() => import('../view/mind.vue')),
        meta: {
            layout: true,
            title: 'WordMap.net'
        },
    },
    {
        path: '/ai-video',
        name: 'ai-video',
        component: defineAsyncComponent(() => import('../view/video-list.vue')),
        meta: {
            layout: true,
            title: 'WordMap.net'
        },
    },
    {
        path: '/ai-video/:pageMode/:childPageMode?',
        name: 'video-detail',
        component: defineAsyncComponent(() => import('../view/video-theater.vue')),
        beforeEnter: (to, from, next) => {
            if (to.query.video_id) localStorage.setItem('curVideoId', to.query.video_id);
            if (!to.query.video_id) to.query.video_id = localStorage.getItem('curVideoId');
            next()
        },
        meta: { title: 'WordMap.net' }
    },
    {
        path: '/login',
        name: 'login',
        component: defineAsyncComponent(() => import('../view/login.vue')),
        meta: { title: 'WordMap.net' }
    },
    {
        path: '/video/sentencesearch',
        name: 'video/sentencesearch',
        component: defineAsyncComponent(() => import('../view/sentencesearch.vue')),
        meta: {
            layout: true,
            title: 'WordMap.net'
        },
    },
    {
        path: '/video/lastrecord',
        name: 'video/lastrecord',
        component: defineAsyncComponent(() => import('../view/lastrecord.vue')),
        meta: {
            layout: true,
            title: 'WordMap.net'
        },
    },
    {
        path: "/chatninja/:token",
        name: "tokenLogin",
        component: defineAsyncComponent(() => import('../view/tokenLogin.vue')),
        meta: { title: "WordMap.net" },
    },

]

// 创建路由器
const router = createRouter({
    history: routerHistory,
    routes: routes
})

export default router