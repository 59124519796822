import _ from 'lodash';
import { SUBTITLE_TYPES } from '@/contants/playState';
import { addStateToSubTitles } from '@/utils/tools';

const subtitle = {
  namespaced: true,
  state: {
    subtitleInfo: {
      primarySubtitleUrl: '',
      secondarySubtitleUrl: '',
      primarySubtitleList: [],
      secondarySubtitleList: []
    },
    list: [], // 最新一个视频的字幕信息
    stateList: [],
    style: {
      'font-size': '24px',
      'font-weight': '400',
      'font-family': 'PingFang SC'
    },
    subtitleType: SUBTITLE_TYPES[0].value,
    sentence: [], // 视频句子
    curSubtitle: null,
    collectTextStyleIndex: 'highLight'
  },
  getters: {
    styleStr(state) {
      let str = '';
      Object.keys(state.style).map(key => str += `${key}: ${state.style[key]};`)
      return str;
    }
  },
  mutations: {
    updateList(state, list) {
      state.list = _.cloneDeep(list);
    },
    updateStateList(state, list) {
      state.stateList = _.cloneDeep(list);
    },
    collectWord(state, word) {
      if (!state.stateList.word) state.stateList.word = [];
      const oldWord = state.stateList.word.find(item => item.word_list === word);
      if (oldWord) {
        state.stateList = {
          ...state.stateList,
          word: state.stateList.word.filter(item => item.word_list != oldWord.word_list)
        }
      } else {
        state.stateList = {
          ...state.stateList,
          word: [...state.stateList.word, { word_list: word }]
        }
      }
      const newList = addStateToSubTitles(state.list, state.stateList);
      state.list = _.cloneDeep(newList);
    },
    allMasterWord(state, word) {
      if (!state.stateList.hide) state.stateList.hide = [];
      const oldWord = state.stateList.hide.find(item => item.word_list === word);
      if (oldWord) {
        state.stateList = {
          ...state.stateList,
          hide: state.stateList.hide.filter(item => item.word_list != oldWord.word_list)
        }
      } else {
        state.stateList = {
          ...state.stateList,
          hide: [...state.stateList.hide, { word_list: word }]
        }
      }
      const newList = addStateToSubTitles(state.list, state.stateList);
      state.list = _.cloneDeep(newList);
    },
    updateStyle(state, status) {
      state.style = { ...state.style, ...status };
    },
    updateSubtitle(state, info) {
      state.subtitleInfo = {
        ...state.subtitleInfo,
        ...info
      }
    },
    updateSubtitleType(state, type) {
      state.subtitleType = type;
    },
    updateSentence(state, data) {
      state.sentence = [...state.sentence, ...data];
    },
    updateCurSubTitle(state, data) {
      state.curSubtitle = data;
    },
    updateSentenceLikeById(state, id) {
      let idx = state.list.findIndex(item => item.id === id);
      let list = state.subtitleInfo.primarySubtitleList;
      let isLike = list[idx].is_like;
      list[idx].is_like = !isLike ? 1 : 2;
      state.subtitleInfo = {
        ...state.subtitleInfo,
        primarySubtitleList: list
      }
    },
    updateCollectTextStyle(state, value) {
      state.collectTextStyleIndex = value;
    }
  }
}
export default subtitle